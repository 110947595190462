import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Make Up Day.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Get your team registered for the Granite Games Fall Throwdown here
at The Ville September 14th!  Learn more about the Throwdown here:
 `}<a parentName="em" {...{
            "href": "https://thegranitegames.com/about-throwdowns/"
          }}>{`https://thegranitegames.com/about-throwdowns/   `}</a></em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We also need volunteers for the Granite Games.  Volunteers will
receive a Granite Games Throwdown shirt, Quills coffee, Fuel Meal Prep
Breakfast, Fit Aid, RX Bar, Good Wood Lunch and more!  It’ll be a fun
filled day as a competitor OR a volunteer!  Sign up
at: `}</em></strong><a parentName="p" {...{
        "href": "https://competitioncorner.net/events/2396#.XUoBHS2ZP1w"
      }}><strong parentName="a"><em parentName="strong">{`https://competitioncorner.net/events/2396`}{`#`}{`.XUoBHS2ZP1w`}</em></strong></a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      